import * as React from "react"
import styled from "styled-components"
import ResponsiveEmbed from "react-responsive-embed"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

// styles
const HeroGroup = styled.div`
  max-width: 1344px;
  margin: 0 auto;
`
const HeroCanvas1 = styled.div`
  opacity: 0;
  animation: ResponsiveEmbedAnimation 3s .5s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  width: 100%;
  border-radius: 18px;
  overflow: hidden;
  padding-bottom: 0;
  display: block;
  
  @media (max-width: 640px) {
    display: none;
  }
`

const HeroCanvas2 = styled.div`
  opacity: 0;
  animation: ResponsiveEmbedAnimation 3s .5s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  width: 100%;
  border-radius: 18px;
  overflow: hidden;
  padding-bottom: 0;

  display: none;
  
  @media (max-width: 640px) {
    display: block;
  }
`

const HeroCaption = styled.div`
  font-family: Alpino-Medium, Arial, Helvetica, sans-serif;
  font-size: var(--step--2);
  margin: 0 0;
  color: var(--quasi-black);
  text-align: left;
  width: 100%;
  margin-top: 12px;
  opacity: 0;
  animation: ResponsiveEmbedAnimation 3s 1s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
`

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <div className="Hero">
      <h1 className="hideMe">Homepage</h1>
        <HeroGroup>
          <HeroCanvas1>
            <ResponsiveEmbed 
              src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F9Rgt3nbonCJ2uJVeTl40PK%2Fsample-jam%3Fnode-id%3D0%253A1' 
              allowFullScreen 
              ratio='1:1.1'
              className='deskjam'
            />
          </HeroCanvas1>
          <HeroCanvas2>
          <ResponsiveEmbed 
              src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F9Rgt3nbonCJ2uJVeTl40PK%2Fsample-jam%3Fnode-id%3D0%253A1' 
              allowFullScreen 
              ratio='1:2'
              className='mobilejam'
            />
          </HeroCanvas2>
          <HeroCaption>Above is an active <a href="https://www.figma.com/figjam/" target="_blank" rel="noreferrer">FigJam</a> board showing a diagram of the idea behind brsfstburrito. <a href="mailto:dan.hanasono@gmail.com">Ping me</a> if you wanna jam!</HeroCaption>
        </HeroGroup>
      </div>
    </Layout>
  )
}

export default IndexPage
